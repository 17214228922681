// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, signOut  } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAKu2eKiYhKR7WlLanlWy38oizztyGbhlk",
    authDomain: "giveaway-962fd.firebaseapp.com",
    projectId: "giveaway-962fd",
    storageBucket: "giveaway-962fd.firebasestorage.app",
    messagingSenderId: "540493073640",
    appId: "1:540493073640:web:c2fe241127f796956b4d5d",
    measurementId: "G-PJ6V01V38N"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence set to local.");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

export { db, auth, signOut, signInWithEmailAndPassword };
