import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { addDoc, deleteDoc, doc } from "firebase/firestore";

function EventDashboard() {
    const [selectedEventId, setSelectedEventId] = useState(""); // Default is empty.
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();
    const [newEvent, setNewEvent] = useState("")

    // Fetch events
  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "events");
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventsList);
      if (eventsList.length > 0) {
        setSelectedEventId(eventsList[0].id); // Set default event.
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
      const fetchEventDetails = async () => {
        if (!selectedEventId) return;
  
        const productsCollection = collection(db, "products");
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((product) => product.eventId === selectedEventId);
  
      };
  
      fetchEventDetails();
    }, [selectedEventId]);

  const handleSelectEvent = (eventId) => {
    navigate("/admin", { state: { eventId } });
  }

  const handleAddEvent = async () => {
    if (newEvent) {
      try {
        await addDoc(collection(db, "events"), {
          name: newEvent,
          createdAt: new Date(),
        });
        setNewEvent("");
        alert(`Event "${newEvent}" created successfully!`);
      } catch (error) {
        console.error("Error creating event: ", error);
      }
    }
  }

  const handleDeleteEvent = async (eventName) => {
    if (!eventName) {
      alert("Event name is required to delete.");
      return;
    }
  
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the event "${eventName}"? This will remove all related products and participants!`
    );
  
    if (!confirmDelete) return;
  
    try {
      // Delete the event
      const eventsCollection = collection(db, "events");
      const eventsSnapshot = await getDocs(eventsCollection);
  
      const eventDoc = eventsSnapshot.docs.find(
        (doc) => doc.data().name === eventName
      );
  
      if (!eventDoc) {
        alert("Event not found.");
        return;
      }
  
      await deleteDoc(doc(db, "events", eventDoc.id));
  
      // Delete all related products
      const productsCollection = collection(db, "products");
      const productsSnapshot = await getDocs(productsCollection);
      const productsToDelete = productsSnapshot.docs.filter(
        (product) => product.data().eventName === eventName
      );
  
      await Promise.all(
        productsToDelete.map((productDoc) =>
          deleteDoc(doc(db, "products", productDoc.id))
        )
      );
  
      // Delete all related participants
      const participantsCollection = collection(db, "participants");
      const participantsSnapshot = await getDocs(participantsCollection);
      const participantsToDelete = participantsSnapshot.docs.filter(
        (participant) => participant.data().eventName === eventName
      );
  
      await Promise.all(
        participantsToDelete.map((participantDoc) =>
          deleteDoc(doc(db, "participants", participantDoc.id))
        )
      );
  
      alert(
        `Event "${eventName}" and all its related data have been deleted successfully!`
      );
    } catch (error) {
      console.error("Error deleting event and related data: ", error);
      alert("Failed to delete the event. Please try again.");
    }
  };

  
    
  return (
    <div className="flex justify-center p-8">
      <div className="bg-white p-4 rounded-md shadow-md">
        <h2 className="text-xl font-semibold mb-4">Select an Event</h2>
        <ul className="space-y-2">
          {events.map((event) => (
            <li key={event.id} className="bg-gray-100 p-2 rounded-md">
              <div className="flex space-x-2">
                <button
                  onClick={() => handleSelectEvent(event.name)}
                  className="w-full text-center bg-blue-500 text-white p-2 rounded-md"
                >
                  {event.name}
                </button>
                <button
                  onClick={() => handleDeleteEvent(event.name)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                >
                  Sil
                </button>
              </div>
            </li>
          ))}
        </ul>
        <div className="my-6">
          <input
            type="text"
            placeholder="Event Name"
            value={newEvent}
            onChange={(e) => setNewEvent(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4"
          />
          <button
            onClick={handleAddEvent}
            className="bg-purple-500 text-white px-6 py-2 rounded-md hover:bg-purple-600"
          >
            Create New Event
          </button>
        </div>
      </div>
    </div>
  );
}

export default EventDashboard