import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import Login from "./components/login";
import AdminDashboard from "./components/AdminDashboard";
import SelectEvent from "./components/SelectEvent";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Update state when user logs in/out
      setLoading(false); // Set loading to false after checking auth state
    });

    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator until auth state is resolved
  }

  return (
    <Router>
      <Routes>
        {/* Public Route for Login */}
        <Route path="/login" element={!user ? <Login /> : <Navigate to="/select-event" />} />

        {/* Protected Route for SelectEvent */}
        <Route path="/select-event" element={user ? <SelectEvent /> : <Navigate to="/login" />} />

        {/* Protected Route for AdminDashboard */}
        <Route path="/admin" element={user ? <AdminDashboard /> : <Navigate to="/login" />} />

        {/* Default Route (Redirect to login if no match) */}
        <Route path="/" element={user ? <Navigate to="/select-event" /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
